import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';

import './Filters.css';

import { get_values_kommun, get_values_lan, get_values_duration, get_values_sprak, get_values_studieamnen, get_values_studieform, get_values_studietakt, get_values_anordnare} from '../../valuestore.js';

export default class Filters extends Component{

    selectRef = null;
    valueTest = null;

    async componentDidMount(){
        this.lan = await get_values_lan();
        this.setState({lan: this.lan[0]})
        console.log(this.lan)

        this.kommun = await get_values_kommun();
        this.setState({kommun: this.kommun[0]})
        console.log(this.kommun)

        this.duration = await get_values_duration();
        this.setState({duration: this.duration[0]})
        console.log(this.duration)
    }


    constructor(props) {
        super(props);

        //this.kommuner = get_values_kommuner();
        this.studietakt = get_values_studietakt();
        this.studieform = get_values_studieform();
        this.studieamne = get_values_studieamnen();
        this.sprak = get_values_sprak();
        this.anordnare = get_values_anordnare();

        this.clearAllValues = this.clearAllValues.bind(this);

        this.lan = [];
        this.kommun = [];
        this.duration = [];

        this.selectSearchStyle = {
            control: styles => ({ ...styles, backgroundColor: '#43474E', borderRadius: 1000, fontSize:16, fontWeight: 500, margin: 0, maxHeight: 42, color: '#fff !important', border: '2px solid #D3D3D4'}),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //const color = chroma(data.color);
                return {
                    ...styles,
                    backgroundColor: isFocused ? "#1E90FF" : "#43474E",
                };
            },
          };


        this.selectDropdownStyle = {
            control: (base, state) => ({ ...base, boxShadow: state.isFocused ? 0 : 0, borderColor: state.isFocused ? "#D3D3D4" : "#D3D3D4", "&:hover": {
              borderColor: state.isFocused ? "#D3D3D4" : "#D3D3D4"}, 
              backgroundColor: '#43474E', borderRadius: 1000, fontSize: 16, paddingLeft: 16, fontWeight: 500, margin: 0, maxHeight: 42, color: '#fff !important', border: '2px solid #D3D3D4' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                //const color = chroma(data.color);
                return {
                    ...styles,
                    backgroundColor: isFocused ? "#1E90FF" : "#43474E",
                };
            },
        };

        this.state = { moreFilters: false, 
            anordnare: this.anordnare[0],
            //region: this.kommuner[0],
            studieform: this.studieform[0],
            amne: this.studieamne[0],
            sprak: this.sprak[0]
        }
    }

    async clearAllValues(e) {
        e.preventDefault();

        this.setState({
            anordnare: this.anordnare[0],
            kommun: this.kommun[0],
            lan: this.lan[0],
            duration: this.duration[0],
            amne: this.studieamne[0],
            sprak: this.sprak[0]
        })

        await this.props.resetFilters();
    }

    render() {

        return (
        <Form className="d-flex flex-column gap-4">
            <span className="result-number">Antal träffar: <strong>{this.props.totalElements == 100000? '>' + this.props.totalElements: this.props.totalElements }</strong></span>
            
            <Form.Label>Filtrera</Form.Label>

            <Form.Group controlId="formLocal">
                <label className="mb-1">Län</label>
                <Select value={this.state.lan} isSearchable={false} className="advanced-form-select" defaultValue={this.lan[0]} styles={this.selectDropdownStyle} options={this.lan} onChange={e => { this.props.handleChangeFilter(e,'filter_lan'); this.setState({'lan': e }) }}>
                </Select>
            </Form.Group>

            <Form.Group controlId="formLocal">
                <label className="mb-1">Kommun</label>
                <Select value={this.state.kommun} isSearchable={false} className="advanced-form-select" defaultValue={this.kommun[0]} styles={this.selectDropdownStyle} options={this.kommun} onChange={e => { this.props.handleChangeFilter(e,'filter_kommun'); this.setState({'kommun': e }) }}>
                </Select>
            </Form.Group>

            <Form.Group controlId="formLocal">
                <label className="mb-1">Anställningsform</label>
                <Select value={this.state.duration} isSearchable={false} className="advanced-form-select" defaultValue={this.duration[0]} styles={this.selectDropdownStyle} options={this.duration} onChange={e => { this.props.handleChangeFilter(e,'filter_duration'); this.setState({'duration': e }) }}>
                </Select>
            </Form.Group>

            <Collapse in={this.state.moreFilters}>

                <div id="collapse-filters">
                    <Form.Group controlId="formSubject" className="mb-4">
                        <label className="mb-1">Ämne</label>
                        <Select value={this.state.amne} ref={ref => {this.selectRef2 = ref;}} defaultValue={this.studieamne[0]} styles={this.selectDropdownStyle} options={this.studieamne} onChange={ e => { this.props.handleChangeFilter(e, 'filter_studieamne'); this.setState({'amne': e}) } }>
                        </Select>
                    </Form.Group>

                    <Form.Group controlId="formInstitute" className="mb-4">
                        <label className="mb-1">Anordnare</label>

                        <Select value={this.state.anordnare} isSearchable={false} className="advanced-form-select" defaultValue={this.anordnare[0]} styles={this.selectDropdownStyle} options={this.anordnare} onChange={e => { this.props.handleChangeFilter(e,'filter_anordnare'); this.setState({'anordnare': e }) }}>
                        </Select>

                    </Form.Group>

                    <Form.Group controlId="formLanguage">
                        <label className="mb-1">Språk</label>
                        
                        <Select value={this.state.sprak} isSearchable={false} className="advanced-form-select" defaultValue={this.sprak[0]} styles={this.selectDropdownStyle} options={this.sprak} onChange={e => { this.props.handleChangeFilter(e,'filter_sprak'); this.setState({'sprak': e }) }}>
                        </Select>

                    </Form.Group>

                </div>
            </Collapse>

            <a
                onClick={() => this.setState({moreFilters: !this.state.moreFilters})}//this.setMoreFilters(!this.moreFilters)}
                aria-controls="collapse-filters"
                aria-expanded={ this.state.moreFilters }
                className="link-tsl"
            >
                Visa { this.state.moreFilters ? ("färre") : ("alla")} filter {this.state.moreFilters ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </a>

            <div className="d-flex">
                <button className="btn-tsl" onClick={this.clearAllValues}>Återställ alla filter</button>
            </div>
        </Form>)
    }
}