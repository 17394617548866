import React, { Component } from 'react';

import NavbarTSL from './components/Navbar/Navbar'
import Search from './components/Search/Search'
import { Col, Container, Row } from 'react-bootstrap';
import Filters from './components/Filters/Filters';
import JobCard from './components/JobCard/JobCard';
import FooterTSL from './components/Footer/Footer';

import { get_params } from './filters.js';
import { jobsearch_fetch} from './search.js';
import { get_kommun_from_code } from './valuestore.js';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//this const needs to change to 'true' when the server returns the search results
let isSearched = false;

//this const needs to change to 'true' when the server returns more than 20 search results
let isMoreThan20 = false;
let showMoreButton = false;
let totalElements = 0;
let currentElements = 0;
let currentPage = 0;
const elementsPerPage = 20;

//this const needs to change to 'true' when the server returns no search results
let noResults = false;

export default class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: '',
      result_content: [],
      result_info: { totalElements: 0 },
      filter_start: 'spring 2023',
      filter_lan: '',
      filter_kommun: '',
      filter_duration: '',
      filter_studietakt: '',
      filter_anordnare: '',
      filter_sprak: '',
      job_cards: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
    this.fetchNextPage = this.fetchNextPage.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  async resetFilters() {
    
    this.setState( {
      //filter_start: 'spring 2023',
      filter_lan: '',
      filter_kommun: '',
      filter_duration: '',
      filter_studietakt: '',
      filter_anordnare: '',
      filter_sprak: ''
    }, async function() { await this.update_data() })
  }

  generate_job_cards(content, page_info) {

    let job_cards = [];

    if(content.hits.length > 0){
      for(let i=0;i<content.hits.length;i++) {
        const item = content.hits[i];

        const application_contacts_name = item.application_contacts.length > 0? item.application_contacts[0].name : 'null';
        const application_contacts_email = item.application_contacts.length > 0? item.application_contacts[0].email: 'null';
        const application_contacts_telephone = item.application_contacts.length > 0? item.application_contacts[0].telephone: 'null';

        const application_details_url = item.application_details.url;
        const application_details_email = item.application_details.email;
        const application_details_other = item.application_details.other;

        let deadline = item.application_deadline;
  
        let list = deadline.split('T');
        if(list.length>0) // should always be true
          deadline = list[0];
        
      
        let c = { headline: item.headline, occupation_label: item.occupation.label, 
                  employment_type_label: item.employment_type.label, duration: item.duration.label, 
                  working_hours: item.working_hours_type.label, employer_name: item.employer.name,
                  workplace_address_municipality: item.workplace_address.municipality, salary_type: item.salary_type.label,
                  application_deadline: deadline, 
                  text_formatted: item.description.text_formatted,
                  application_contacts_name: application_contacts_name,
                  application_contacts_email: application_contacts_email,
                  application_contacts_telephone: application_contacts_telephone,
                  applications_details_url: application_details_url,
                  application_details_email: application_details_email,
                  application_details_other: application_details_other
                };

        job_cards.push(c);

      // Not used atm:
      // Eligible for student aid: item.info.eligibleForStudentAid ? 'Student aid: ' + item.info.eligibleForStudentAid.code : ''
      }
    }

    return job_cards;

  }

  reset_data() {

    isMoreThan20 = false;
    noResults = false;
    totalElements = 0;
    currentElements = 0;
    currentPage = 0;

    const [content, page_info] = [0, 0];
  
    const job_cards = this.generate_job_cards(content, page_info);

    this.setState({ result_content: content })
    this.setState({ result_info: page_info })
    this.setState({ job_cards: job_cards })
  }

  async update_data(page = 0) {

    const search_string = this.state['search'];
    const filter_start = this.state['filter_start'];
    const filter_kommun = this.state['filter_kommun'];
    const filter_lan = this.state['filter_lan'];
    const filter_duration = this.state['filter_duration'];
    const filter_studietakt = this.state['filter_studietakt'];
    const filter_sprak = this.state['filter_sprak'];
    const filter_anordnare = this.state['filter_anordnare'];

    const [content, page_info] = await jobsearch_fetch(page, search_string, filter_kommun, filter_lan, filter_duration);//ug_susanavet_fetch(page, search_string, filter_start, filter_kommun, filter_studieform, filter_studieamne, filter_studietakt, filter_sprak, filter_anordnare);

    if (page_info.totalElements > elementsPerPage){
      noResults = false;
      isMoreThan20 = true;
      showMoreButton = true;
      totalElements = page_info.totalElements;
      currentElements = page_info.number*elementsPerPage + elementsPerPage;
    } else if (page_info.totalElements == 0){
      noResults = true;
    }
    else {
      isMoreThan20 = false;
      noResults = false;
    }

    if(currentElements>totalElements) {
      currentElements = totalElements;
      showMoreButton = false;
    }

    const job_cards = this.generate_job_cards(content, page_info);

    if(page == 0) {
      this.setState({ result_content: content })
      this.setState({ result_info: page_info })
      this.setState({ job_cards: job_cards })
    }
    else { // append
      this.state['job_cards'].push(...job_cards);
      this.setState( { job_cards: this.state['job_cards'] });
    }
  }

  async fetchNextPage(e) { 
    e.preventDefault()
    currentPage++;
    await this.update_data(currentPage);
  }

  async handleChange(event) {
    this.setState({ search: event.target.value });
    //await this.update_data();
  }

  async handleSubmit(event) {
    event.preventDefault();
    //this.reset_data();
    await this.update_data();
    isSearched = true;
  }



  async handleChangeFilter(event, filter_name) {
    
    let d = {}

    if('value' in event) { // event from react-select component
      d[filter_name] = event.value;
    }
    else if('target' in event) // standard event
      d[filter_name] = event.target.value;
    else {
      console.log('Error: Could not interpret event.')
    }

    await this.setState(d);
    await this.update_data();
  }

  async handleChangeStart(start_value) {
    await this.setState({ filter_start: start_value })//event.target.value });
    await this.update_data();
  }

  render() {
    return (
      <div className="App">
        <div className="full-screen">
          <NavbarTSL/>

          <section className="hero">
            <Container>
              <Row>
                <Col md={{ span: 8, offset: 2 }} className="hero-content text-center d-flex flex-column">
                  <h1>Välkommen till Jobbkatalogen</h1>
                  <p className="preamble">
                      Hitta ditt jobb
                  </p>
                  <Search handleChange={this.handleChange} handleSubmit={this.handleSubmit} handleChangeStart={this.handleChangeStart}/>
                </Col>
              </Row>
            </Container>
          </section>

          {isSearched ?
            <section id="searchResult">
              <Container>
                <Row className="gap-lg-4 gap-md-3 gap-5">
                  <Col md={4} lg={3}>
                    <Filters totalElements={this.state.result_info.totalElements} handleChangeFilter={this.handleChangeFilter} resetFilters={this.resetFilters}/>
                  </Col>

                  <Col className="courses">
                    { this.state.job_cards.map((c, i) => 
                      <JobCard key={i}
                        headline={ c.headline }
                        occupation_label={ c.occupation_label } 
                        employment_type_label = { c.employment_type_label }
                        duration={ c.duration }
                        working_hours={ c.working_hours } 
                        employer_name = { c.employer_name }
                        workplace_address_municipality = { c.workplace_address_municipality }
                        salary_type = { c.salary_type }
                        application_deadline = { c.application_deadline }
                        text_formatted={ c.text_formatted }
                        application_contacts_name={ c.application_contacts_name }
                        application_contacts_email= { c.application_contacts_email } 
                        application_contacts_telephone={ c.application_contacts_telephone } 
                        applications_details_url={ c.applications_details_url }
                        application_details_email={ c.application_detailts_email } 
                        application_details_other={ c.application_details_other } />
                    ) }
                    
                    {noResults ?
                      <span className="text-center">Inga resultat hittades för denna sökning</span>
                    : <></>}

                    {isMoreThan20 && !noResults ?
                      <div className="load-more">
                        <span>{currentElements} av {totalElements} träffar</span>
                        {showMoreButton? <a className="link-tsl" onClick={this.fetchNextPage}>Visa nästa {elementsPerPage} sökträffar</a>: <></>}
                      </div>
                    : <></>}
                  </Col>
                </Row>
              </Container>
            </section>
          : <></>}
        </div>

        <FooterTSL/>
      </div>
    );
  }
}