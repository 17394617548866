import { get_params, ug_get_params } from './filters.js';

const api_url_jobsearch = 'https://jobsearch.api.jobtechdev.se/search'
// map and combine incoming data into one array
function combine_data(js_events, js_infos, js_providers) {

    const id2provider = Object.fromEntries(js_providers.content.map(x => [x.content.educationProvider.identifier, x.content.educationProvider]));
    const id2info = Object.fromEntries(js_infos.content.map(x => [x.content.educationInfo.identifier, x.content.educationInfo]));

    let res = []
    for (let i = 0; i < js_events.content.length; i++) {

        const event = js_events.content[i].content.educationEvent;
        const info = id2info[js_events.content[i].content.educationEvent.education];

        if (js_events.content[i].content.educationEvent.provider.length > 1) {
            console.log('WARNNG: provider.length>1')
        }

        const provider = id2provider[js_events.content[i].content.educationEvent.provider[0]];

        let c = { 'event': event, 'info': info, 'provider': provider }

        res.push(c)
    }

    //
    return res;
}

export async function jobsearch_fetch(page = 0, search_string, filter_kommun = '', filter_lan = '', filter_duration = '') {
    
    const nr_per_page = 20;
    let url_search = api_url_jobsearch;

    url_search += '?q='+ search_string + '&limit=' + nr_per_page + '&offset=' + page*nr_per_page;
    if (filter_lan != '') {
        url_search += '&region=' + filter_lan;
    }
    if (filter_kommun != '') {
        url_search += '&municipality=' + filter_kommun;
    }

    if (filter_duration != '') {
        url_search += '&worktime-extent=' + filter_duration;
    }

    const res = await (await fetch(url_search, {
        headers: {
            "accept": "application/vnd.skolverket.plannededucations.api.v3.hal+json",
        }
    } ) ).json();


    const data = res;//combine_data(js_events, js_infos, js_providers);
    const summary = {'totalElements': res['total']['value'], 'number': page }

    return [data, summary]
    }


