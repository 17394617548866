import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import './JobCard.css';

export default function JobCard(props) {

    const [openCard, setOpenCard] = useState(0);

    return (


        <div className="course-card-style">
            <Row className="info-div justify-content-between">
                <Col fluid="true" className="mb-2 mb-lg-0">
                    <h2 className="mb-2">{props.headline}</h2>
                    { props.application_deadline ? 

                        <div className="d-flex align-items-center gap-2">
                            <div className="status-on"></div>
                            <span>Öppen för ansökan</span><br/>
                        </div>
                    : '' }

                    { props.application_deadline ? 
                        <div className="d-flex align-items-center gap-2">
                            <div className="status-on"></div>
                            <span>Sista ansökningsdag: {props.application_deadline }</span>
                        </div>
                    : '' }

                </Col>
                <Col lg="auto">
                    <button type="submit" className="btn-tsl w-100 mb-3" onClick={()=> window.open(props.applications_details_url, "_blank")}>Till arbetsgivarens webbplats</button>
                    <a
                        onClick={() => setOpenCard(!openCard)}
                        aria-controls="collapse-info"
                        aria-expanded={openCard}
                        className="link-tsl justify-content-end"
                    >
                        Visa {openCard ? ("mindre") :("mer")} {openCard ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </a>
                </Col>
            </Row>

            <Row className="info-div">
                <Col lg={6} className="mb-4 mb-lg-0">
                    <div className="mb-4">
                        <h4>Yrke</h4>
                        <p>{props.occupation_label}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Anställningsform</h4>
                        <p>{props.employment_type_label}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Varaktighet</h4>
                        <p>{props.duration}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Omfattning</h4>
                        <p>{props.working_hours}</p>
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="mb-4">
                        <h4>Arbetsgivare</h4>
                        <p>{props.employer_name}</p>
                    </div>


                    <div className="mb-4">
                        <h4>Kommun</h4>
                        <p>{props.workplace_address_municipality}</p>
                    </div>

                    <div className="mb-4">
                        <h4>Lönetyp</h4>
                        <p>{props.salary_type}</p>
                    </div>

                    <div>
                        <h4>Sista ansökningsdag</h4>
                        <p>{props.application_deadline}</p>
                    </div>
                    
                </Col>
            </Row>
      
            <a
                onClick={() => setOpenCard(!openCard)}
                aria-controls="collapse-info"
                aria-expanded={openCard}
                className="link-tsl justify-content-end d-lg-none"
            >
                Visa {openCard ? ("mindre") :("mer")} {openCard ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
            </a>

            <Row>
                <Collapse in={openCard}>
                    <div id="collapse-info">
                        <div className="info-div">
                            <h3 className="mb-3">Om jobbet</h3>
                            <div dangerouslySetInnerHTML={{__html:(props.text_formatted)}} />
                        </div>

                        <div>
                            <h3 className="mb-3">Kontakt</h3>
                            <Row>
                                {props.application_contacts_name ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Arbetsgivare</h4>
                                        <p>{props.application_contacts_name}</p>
                                    </Col> 
                                : <></>}
                                
                                {props.website ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Webbplats</h4>
                                        <p><a href={props.applications_details_url} target="_blank">Till ansökan</a></p>
                                    </Col>
                                : <></>}
                                
                                {props.address ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Address</h4>
{/*                                        <p>{props.address}</p> 

                  application_details_email: application_details_email,
                  application_details_other: application_details_other
                  */}
                                    </Col>
                                : <></>}
                                
                                {props.email ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>E-Post</h4>
                                        <p>{props.application_contacts_email}</p>
                                    </Col>
                                : <></>}
                                
                                {props.phone ? 
                                    <Col lg={6} className="mb-4">
                                        <h4>Telefon</h4>
                                        <p>{props.application_contacts_telephone}</p>
                                    </Col>
                                : <></>}
                            </Row>
                        </div>



                        <Row>
                            <a
                                onClick={() => setOpenCard(!openCard)}
                                aria-controls="collapse-info"
                                aria-expanded={openCard}
                                className="link-tsl justify-content-end"
                            >
                                Visa {openCard ? ("mindre") :("mer")} {openCard ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                            </a>
                        </Row>
                    </div>
                </Collapse>
            </Row>

            
        </div>
    )
}